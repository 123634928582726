import React, { useEffect } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import Img from 'gatsby-image'

import Nav from '../../components/nav'
import SEO from '../../components/seo'
import Footer from '../../components/footer'
import Box from '../../components/box'
import checkUtmParams from '../../utils/checkUtmParams'

import useHotjarScript from '../../utils/hooks/useHotjarScript'
import IntegrationSection from '../../components/integrationsSection'
import HeroVideoBackground from '../../components/heroVideoBackground'
import Button from '../../components/button'
import FaqSection from '../../components/faqSection'
import { InnerSection, Section } from '..'
import BookCallModal from '../../components/bookCallModal'
import HeroVideo from '../../components/heroVideo'

const EngineeringPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        
        organizeVideos: file(
          relativePath: { eq: "1.3/engineering-organize-videos.png" }
        ) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        engineeringVideoCards: file(
          relativePath: { eq: "1.3/engineering-video-cards.png" }
        ) {
          childImageSharp {
            fixed(width: 328, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        leadershipPostCards: file(
          relativePath: { eq: "1.3/leadership-post-cards.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 280, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        engineeringVideoReader: file(
          relativePath: { eq: "1.3/engineering-video-reader.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 280, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        engineeringComments: file(
          relativePath: { eq: "1.3/engineering-comments.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 280, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        engineeringStreams: file(
          relativePath: { eq: "1.3/engineering-streams.png" }
        ) {
          childImageSharp {
            fixed(width: 328, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        engineeringVideoTranscripts: file(
          relativePath: { eq: "1.3/engineering-video-transcripts.png" }
        ) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        engineeringVideoChapters: file(
          relativePath: { eq: "1.3/engineering-video-chapters.png" }
        ) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const [isBookCallModalOpen, setIsBookCallModalOpen] = React.useState(false)

  const integrations = []

  const toExtensionDownload = () => {
    navigate('browser-extension')
  }

  useEffect(() => {
    checkUtmParams()
  }, [])

  useHotjarScript()

  return (
    <>
      <Nav />
      <BookCallModal
        open={isBookCallModalOpen}
        onClose={() => {
          setIsBookCallModalOpen(false)
        }}
      />
      <Box>
        <SEO
          title="Pulse - Message boards for modern teams"
          description="Pulse is a platform for team alignment. Engineered to help modern teams stay more connected and aligned by communicating more openly and asynchronously."
          ogImage="/assets/ogimages/pulse.png"
          twImage="/assets/ogimages/twitter/pulse.png"
        />
        <HeroVideoBackground className='-top-[116px] tabletLToLower:top-[392px]' />
        <div
          className='mt-[64px]'
        >
          <Section>
            <InnerSection className='flex justify-between pt-48 pb-[121.5px] tabletLToLower:pb-[40px] tabletLToLower:flex-col'>
              <div
                className='w-[496px] tabletLToLower:w-full pt-[30px]'
              >
                <div
                  className='text-base leading-22 text-gray-foreground-400 tabletLToLower:w-full tabletLToLower:text-center'
                >
                  Go async and ship more.
                </div>
                <div className='font-display mt-16 leading-[64px] text-[60px] tabletLToLower:text-4xl tabletLToLower:leading-[42px] tabletLToLower:text-center'>
                  Engineering
                </div>
                <div className='mt-16 text-gray-foreground font-display tabletLToLower:text-center tabletLToLower:text-base tabletLToLower:leading-[20px] max-w-[420px] text-xl leading-24'>
                  Organize engineering content in one place. From weekly updates and proposals to internal meetings recordings and feature demos – all searchable and transcribed.
                </div>
                <div className='mt-24 flex tabletLToLower:justify-center'>
                  <Button to='/signup/' className='px-18'>
                    <span className='tabletLToLower:hidden block'>
                      Try Pulse for free
                    </span>
                    <span className='tabletLToLower:block hidden'>
                      Create an account
                    </span>
                  </Button>
                  <Button className='ml-12 px-18 tabletLToLower:!hidden' secondary onClick={() => setIsBookCallModalOpen(true)}>
                    Get Demo
                  </Button>
                </div>
              </div>
              <HeroVideo />
            </InnerSection>
          </Section>
          <Section
            className='flex justify-center text-center pt-96 tabletLToLower:pt-64 pb-12'
          >
            <InnerSection style={{ width: '650px' }} className='tabletLToLower:!w-full'>
              <div className='text-5xl leading-52 font-display tabletLToLower:text-4xl tabletLToLower:leading-[42px] text-center'>
                Save thousands of engineering hours
              </div>
              <div className='text-gray-foreground leading-24 text-xl mt-16 flex justify-center tabletLToLower:text-base tabletLToLower:leading-[20px]'>
                <div style={{ width: '496px' }} className='tabletLToLower:!w-full'>
                  Don’t get engineers bogged down in unnecessary meetings. With Pulse, non-participants can watch engineering meetings on their own time, listen to recordings at 2x, and jump to things they care about.
                </div>
              </div>
            </InnerSection>
          </Section>
          <Section
            className='grid place-content-center pt-48 tabletLToLower:pt-32'
          >
            <div
              className='grid-cols-2 gap-x-16 gap-y-24 grid tabletLToLower:grid-cols-1'
            >
              <div className='bg-gray-foreground-100 rounded-2xl pt-24 px-18 w-[328px] tabletLToLower:w-full h-[400px] text-center overflow-hidden'>
                <div className='flex justify-center text-center'>
                  <div
                    className='rounded-full text-2xl leading-30 border-[1px] border-solid border-black box-border h-[44px] w-[44px] py-7 font-display'
                    style={{
                      fontFeatureSettings: `'pnum' on, 'onum' on`
                    }}
                  >
                    01
                  </div>
                </div>
                <div className='mt-12 text-xl leading-26 font-display'>
                  Your meetings recordings
                </div>
                <div className='mt-8 text-base leading-22 text-gray-foreground'>
                  All transcribed and searchable
                </div>
                <div className='mt-[5px] grid place-content-center'>
                  <Img
                    fadeIn={false}
                    fixed={data.engineeringVideoCards.childImageSharp.fixed}
                  />
                </div>
              </div>

              <div className='bg-gray-foreground-100 rounded-2xl pt-24 px-18 w-[328px] tabletLToLower:w-full h-[400px] text-center overflow-hidden'>
                <div className='flex justify-center text-center'>
                  <div
                    className='rounded-full text-2xl leading-30 border-[1px] border-solid border-black box-border h-[44px] w-[44px] py-7 font-display'
                    style={{
                      fontFeatureSettings: `'pnum' on, 'onum' on`
                    }}
                  >
                    02
                  </div>
                </div>
                <div className='mt-12 text-xl leading-26 font-display'>
                  Watch meetings at 2x
                </div>
                <div className='mt-8 text-base leading-22 text-gray-foreground'>
                  And create time out of thin air.
                </div>
                <div className='mt-[18.9px] overflow-hidden'>
                  <Img
                    fadeIn={false}
                    fluid={data.engineeringVideoReader.childImageSharp.fluid}
                  />
                </div>
              </div>

              <div className='bg-gray-foreground-100 rounded-2xl pt-24 px-18 w-[328px] tabletLToLower:w-full h-[400px] text-center overflow-hidden'>
                <div className='flex justify-center text-center'>
                  <div
                    className='rounded-full text-2xl leading-30 border-[1px] border-solid border-black box-border h-[44px] w-[44px] py-7 font-display'
                    style={{
                      fontFeatureSettings: `'pnum' on, 'onum' on`
                    }}
                  >
                    03
                  </div>
                </div>
                <div className='mt-12 text-xl leading-26 font-display'>
                  Follow up with questions
                </div>
                <div className='mt-8 text-base leading-22 text-gray-foreground'>
                  Discuss & make inclusive decisions.
                </div>
                <div className='mt-[16px] w-full'>
                  <Img
                    fadeIn={false}
                    fluid={data.engineeringComments.childImageSharp.fluid}
                  />
                </div>
              </div>

              <div className='bg-gray-foreground-100 rounded-2xl pt-24 px-18 w-[328px] tabletLToLower:w-full h-[400px] text-center overflow-hidden'>
                <div className='flex justify-center text-center'>
                  <div
                    className='rounded-full text-2xl leading-30 border-[1px] border-solid border-black box-border h-[44px] w-[44px] py-7 font-display'
                    style={{
                      fontFeatureSettings: `'pnum' on, 'onum' on`
                    }}
                  >
                    04
                  </div>
                </div>
                <div className='mt-12 text-xl leading-26 font-display'>
                  Onboard engineers faster
                </div>
                <div className='mt-8 text-base leading-22 text-gray-foreground'>
                  Instant access to previous meetings.
                </div>
                <div className='mt-[14px] grid place-content-center overflow-hidden'>
                  <Img
                    fadeIn={false}
                    fixed={data.engineeringStreams.childImageSharp.fixed}
                  />
                </div>
              </div>

            </div>
          </Section>
          <div className='mt-[144px] tabletLToLower:mt-64' />
          <Section>
            <InnerSection className='pt-48 px-104 bg-gray-foreground-100 tabletLToLower:hidden' style={{ borderRadius: '32px' }}>
              <div className='flex justify-center'>
                <div className='text-5xl leading-52 w-640 text-center font-display'>
                  Your videos in one place. More signals, less noise.
                </div>
              </div>
              <div className='flex justify-center'>
                <div className='mt-16 text-gray-foreground text-xl leading-24 text-center w-496'>
                  Make all engineering video meetings available on-demand so that everyone can watch, comment, or search for them at any time.
                </div>
              </div>
              <div className='flex justify-center'>
                <div className='mt-24'>
                  <Button to='/signup/'>
                    Try Pulse for free
                  </Button>
                </div>
              </div>
              <div className='mt-48'>
                <Img
                  fluid={data.organizeVideos.childImageSharp.fluid}
                  fadeIn={false}
                />
              </div>
            </InnerSection>
          </Section>
          <Section>
            <InnerSection className='mt-24 grid grid-cols-2 gap-24 font-display tabletLToLower:hidden'>
              <div className='px-48 bg-gray-foreground-100 text-3xl leading-36 pt-48' style={{ borderRadius: '32px' }}>
                <div className='w-full max-w-[392px]'>
                  <span>
                    Transcripts.
                  </span>
                  <span className='text-gray-foreground'>
                    {' '}Available in minute and instantly searchable.
                  </span>
                </div>
                <div className='mt-48 pb-48'>
                  <Img
                    fluid={data.engineeringVideoTranscripts.childImageSharp.fluid}
                    fadeIn={false}
                  />
                </div>
              </div>
              <div className='bg-gray-foreground-100 text-3xl leading-36 overflow-hidden' style={{ borderRadius: '32px' }}>
                <Img
                  fluid={data.engineeringVideoChapters.childImageSharp.fluid}
                  fadeIn={false}
                />
                <div className='m-48 w-full max-w-[392px]'>
                  <span>
                    Chapters.
                  </span>
                  <span className='text-gray-foreground'>
                    {' '}Highlight specific parts of your videos with chapters.
                  </span>
                </div>
              </div>
            </InnerSection>
          </Section>
          <div className='mt-[192px] tabletLToLower:mt-64' />
          <IntegrationSection team='engineering' />
          <FaqSection />
        </div>
        <Footer />
      </Box>
    </>
  )

}

export default EngineeringPage
